import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"


const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 19" />
    <div>
      <h1>Luke 19</h1>
      <p>God dag mamma!</p>
      <p>Tenkte jeg skulle starte dagen med et dikt.</p>
      <p>Jeg er en Litjen, du er en Litjen, pappa er en Pappa Litjen. Sammen er vi Litjeners.</p>
      <p>Sånn, var jeg flink?</p>
      <p>I tillegg til klutene er det i dag en til ekstragave som jeg har ordnet til husholdningen.</p>
      <p>Det var rett og slett noe som manglet for at det lille badet skulle oppleves helhetlig. Ta en titt :)</p>
      <p>Hilsen Litjen</p>
    </div>
  </Layout>
)

export default LitjenLuke